import React, { useState, useEffect, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import { useNavigate } from 'react-router-dom';

// Clé publique Stripe
const stripePromise = loadStripe('pk_live_51QCM3TC1CTdrVp3nVXVYIs45qdx7oppriJ05lTqnyy1n9zDnAKiIGMBZis35zCobWyY1lPmAO42379LqtQOg9Idp00VXIfvnma');

const Payment = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
    const total = localStorage.getItem('totalAmount') || 0;
    setTotalAmount(parseFloat(total));
  }, []);

  // Utilisation de useCallback pour mémoriser la fonction createPaymentIntent
  const createPaymentIntent = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: Math.round(totalAmount * 100) }), // Stripe prend les montants en centimes
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    } catch (error) {
      console.error('Error during payment intent creation:', error);
    }
  }, [totalAmount]); // Ajouter totalAmount comme dépendance

  // Mettre à jour le hook useEffect pour inclure createPaymentIntent dans le tableau des dépendances
  useEffect(() => {
    if (totalAmount > 0) {
      createPaymentIntent();
    }
  }, [totalAmount, createPaymentIntent]); // Inclure createPaymentIntent ici

  const handlePaymentSuccess = async (paymentIntentId, email, phone) => {
    const orderData = {
      email,
      phone,
      items: cartItems,
      total: totalAmount,
      paymentIntentId,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/payment-success`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to save order');
      }
  
      const result = await response.json();
      console.log('Order saved:', result);
  
      localStorage.removeItem('cart');
      localStorage.removeItem('totalAmount');
  
      // Redirection vers la page de confirmation
      navigate('/confirmation');
    } catch (error) {
      console.error('Error saving order:', error);
      alert('Une erreur s\'est produite lors de la sauvegarde de votre commande.');
    }
  };

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-3xl font-bold mb-4">Paiement</h2>
      
      {/* Affichage du montant total */}
      <div className="mb-4">
        <h3 className="text-xl font-semibold">Montant total à payer :</h3>
        <p className="text-2xl font-bold text-green-600">{totalAmount.toFixed(2)} €</p>
      </div>

      {/* Formulaire de paiement */}
      {clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} onSuccess={handlePaymentSuccess} />
        </Elements>
      )}
    </div>
  );
};

export default Payment;
