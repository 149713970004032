import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CheckoutForm = ({ clientSecret, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            email,
            phone,
          },
        },
      }
    );

    if (error) {
      setErrorMessage(error.message);
      setSuccessMessage(''); // Réinitialiser le message de succès
    } else {
      setSuccessMessage('Paiement réussi ! Un email de confirmation vous a été envoyé.');
      onSuccess(paymentIntent.id, email, phone); // Passer l'email et le téléphone à onSuccess
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="border p-2 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label>Téléphone</label>
        <input
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          className="border p-2 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <CardElement className="border p-4 rounded w-full" />
      </div>
      <button type="submit" disabled={!stripe} className="bg-green-600 text-white py-2 px-4 rounded">
        Payer
      </button>
      {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
      
      {/* Section pour les icônes des cartes de paiement */}
      <div className="mt-4 flex justify-center">
        <img src="/images/carte.png" alt="Visa" className="h-8 mx-2" />
        <img src="/images/secure-payment_15539807.png" alt="SecurePayment" className="h-8 mx-2" />
        <img src="/images/mastercard.png" alt="MasterCard" className="h-8 mx-2" />
        <img src="/images/bancontact.png" alt="Bancontact" className="h-8 mx-2" />
        {/* Ajoute d'autres icônes si nécessaire */}
      </div>
    </form>
  );
};

export default CheckoutForm;
