import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../stylessheets/home.css';

const products = [
  { id: 1, name: "T-Shirt N'JOY", price: 35.99, sizes: ['S', 'M', 'L', 'XL'], image: '/assert/IMG_8699.jpg' },
  { id: 2, name: "Cup N'JOY", price: 12.99, description: 'Cup personnalisée Njoy', image: '/assert/IMG_8700.jpg' },
  { id: 3, name: 'Pack T-shirt + Cup', price: 45, image: '/assert/IMG_8711.jpg' },
];

const Home = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const videoRef = useRef(null); // Référence à l'élément vidéo

  // Fonction pour gérer l'apparition de la flèche de défilement
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollTopButton(true);
    } else {
      setShowScrollTopButton(false);
    }
  };

  // Effet pour attacher/détacher l'écouteur d'événements de défilement
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Forcer la lecture de la vidéo après le chargement de la page
  useEffect(() => {
    const videoElement = videoRef.current;

    // Vérifier si la vidéo est prête
    const playVideo = () => {
      if (videoElement && videoElement.readyState >= 2) {
        videoElement.play().catch((error) => {
          console.log('Autoplay failed:', error);
        });
      }
    };

    // Attendre 500ms et tenter de jouer la vidéo si elle n'est pas encore prête
    const startVideo = () => {
      if (!videoElement || videoElement.readyState < 2) {
        setTimeout(startVideo, 500);
      } else {
        playVideo();
      }
    };

    // Forcer la lecture peu de temps après le chargement de la page
    startVideo();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen pt-18 bg-gray-100">
      <div className="relative w-full h-[70vh]">  {/* Hauteur de 70% de l'écran */}
        <video
          ref={videoRef}
          src="/videos/njca_4.mp4"
          autoPlay
          muted
          loop
          playsInline  // Ajout de playsinline
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Texte de présentation */}
      <div className="text-center mb-8 px-4 mb-2">
        <h1 className="text-4xl font-bold text-dark-600 linotte-font">
          NEW ARRIVALS !
        </h1>
      </div>

      {/* Affichage des produits */}
      <div className="p-4 w-full max-w-7xl">
        {/* Deux premières images côte à côte */}
        <div className="flex justify-center space-x-8 mb-8">
          <div className="bg-white shadow-md p-8 w-96 h-72 flex flex-col items-center hover:scale-105 transition-transform duration-300">
            <Link to={`/product/${products[0].id}`} className="flex flex-col items-center">
              <img src={products[0].image} alt={products[0].name} className="w-full h-44 object-contain mb-1 rounded" />
              <h2 className="text-xl font-bold text-center linotte-font">{products[0].name}</h2>
            </Link>
          </div>
          <div className="bg-white shadow-md p-8 w-96 h-72 flex flex-col items-center hover:scale-105 transition-transform duration-300">
            <Link to={`/product/${products[1].id}`} className="flex flex-col items-center">
              <img src={products[1].image} alt={products[1].name} className="w-full h-44 object-contain mb-1 rounded" />
              <h2 className="text-xl font-bold text-center linotte-font">{products[1].name}</h2>
            </Link>
          </div>
        </div>
        {/* Image du produit en dessous, centrée */}
        <div className="flex justify-center">
          <div className="bg-white shadow-md p-8 w-96 h-72 flex flex-col items-center hover:scale-105 transition-transform duration-300">
            <Link to={`/product/${products[2].id}`} className="flex flex-col items-center">
              <img src={products[2].image} alt={products[2].name} className="w-full h-44 object-contain mb-1 rounded" />
              <h2 className="text-xl font-bold text-center linotte-font">{products[2].name}</h2>
            </Link>
          </div>
        </div>
      </div>

      {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 bg-black text-white p-3 rounded-full shadow-lg hover:bg-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            viewBox="0 0 24 24"
            stroke="black"
            strokeWidth={2}
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7-7-7 7" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Home;
