import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../stylessheets/home.css';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Récupérer le panier depuis localStorage au chargement du composant
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
  }, []);

  const handleRemoveItem = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedCart = cartItems.map((item, i) => {
      if (i === index) {
        return { ...item, quantity: Math.max(1, newQuantity) }; // S'assurer que la quantité est au moins 1
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  // Calculer le total
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <div className="relative bg-gray-30 min-h-screen">
      {/* Contenu du panier */}
      <div className="pt-20 p-6 bg-white rounded-lg shadow-md mx-4 my-6 max-w-4xl mx-auto">
        <h1 className="text-3xl linotte-font mb-4 text-gray-800">Votre Panier</h1>
        {cartItems.length === 0 ? (
          <p className="text-gray-600 linotte-font">Votre panier est vide.</p>
        ) : (
          <ul className="space-y-4">
            {cartItems.map((item, index) => (
              <li key={index} className="flex items-center border-b pb-4 mb-4">
                <img 
                  src={item.image} 
                  alt={item.name} 
                  className="w-16 h-16 object-contain rounded-md shadow-md mr-4" 
                />
                <div className="flex-grow">
                  <p className="text-lg linotte-font text-gray-800">{item.name}</p>
                  {/* Afficher la taille uniquement si elle est définie */}
                  {item.size && (
                    <p className="text-sm linotte-font text-gray-600">Taille: {item.size}</p>
                  )}
                  <div className="flex items-center">
                    <input 
                      type="number" 
                      value={item.quantity} 
                      min="1" 
                      onChange={(e) => handleQuantityChange(index, Number(e.target.value))}
                      className="w-16 border border-gray-300 rounded px-2 py-1"
                    />
                    <p className="text-gray-600 ml-2 linotte-font">Quantité</p>
                  </div>
                  <p className="linotte-font text-gray-800">Prix: {(item.price * item.quantity).toFixed(2)}€</p>
                </div>
                <button
                  onClick={() => handleRemoveItem(index)}
                  className="bg-red-600 text-white py-1 px-2 rounded transition duration-200 hover:bg-red-700 linotte-font"
                >
                  Supprimer
                </button>
              </li>
            ))}
          </ul>
        )}
        <div className="flex justify-between mt-4">
          <p className="text-lg linotte-font text-gray-800">Total: {calculateTotal()}€</p>
          <Link 
            to={cartItems.length > 0 ? "/payment" : "#"}
            className={`py-2 px-4 rounded transition duration-200 linotte-font ${cartItems.length > 0 ? "bg-green-600 text-white hover:bg-green-700" : "bg-gray-400 text-white cursor-not-allowed"}`}
            onClick={(e) => {
              if (cartItems.length > 0) {
                // Stocker le montant total dans localStorage
                const total = calculateTotal();
                localStorage.setItem('totalAmount', total);
              } else {
                e.preventDefault();  // Empêcher la navigation si le panier est vide
                alert("Votre panier est vide, ajoutez des articles avant de payer.");
              }
            }}
          >
            Payer
          </Link>
        </div>
        <div className="mt-4">
          <Link to="/" className="text-green-600 hover:text-green-800 linotte-font underline">
            Continuer vos achats
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
