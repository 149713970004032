// src/components/Confirmation.js

import React from 'react';
import { Link } from 'react-router-dom';

const Confirmation = () => {
  return (
    <div className="container mx-auto mt-8 text-center">
      <h2 className="text-3xl font-bold mb-4">Merci pour votre commande !</h2>
      <p className="mb-4">Votre paiement a été accepté et votre commande est en cours de traitement.</p>
      <p>Un email de confirmation vous sera envoyé sous peu.</p>
      <p>Pensez à vérifier vos spam !</p>
      <Link to="/" className="mt-4 inline-block bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200">
        Retour à l'accueil
      </Link>
    </div>
  );
};

export default Confirmation;
