import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import des icônes pour le menu hamburger
import '../stylessheets/navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Fonction pour ouvrir/fermer le menu mobile
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center p-4">

        {/* Menu Hamburger - Visible uniquement sur mobile (à gauche) */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMobileMenu} className="text-black">
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Lien Accueil pour Desktop - Masqué sur mobile */}
        <div className="hidden md:flex items-center">
          <Link to="/" className="text-black text-lg linotte-font">
            Accueil
          </Link>
        </div>

        {/* Logo centré */}
        <div className="flex- text-center">
          <Link to="/" className="relative">
            <img src="/logo_njoy_black.svg" alt="Njoy Logo" className="w-24 mx-auto logo-straighten" />
          </Link>
        </div>

        {/* Panier à droite */}
        <div className="flex items-center">
          <Link to="/panier" className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3h18l-1.68 10.74a2.25 2.25 0 01-2.23 1.99H6.91a2.25 2.25 0 01-2.23-1.99L3 3z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 21h.01M15 21h.01"
              />
            </svg>
          </Link>
        </div>
      </div>

      {/* Menu déroulant mobile */}
      {isMobileMenuOpen && (
        <div className="md:hidden flex flex-col items-center bg-white shadow-md py-4">
          <Link to="/" className="text-black text-lg font-medium mb-2" onClick={toggleMobileMenu}>
            Accueil
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
