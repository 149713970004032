import React from 'react';
import { FaInstagram, FaTwitter, FaTiktok, FaFacebook } from 'react-icons/fa'; // Importer l'icône Facebook
import '../stylessheets/home.css'

const Footer = () => {
    return (
        <footer className="bg-white-800 text-dark py-4">
            <div className="container mx-auto flex flex-col items-center">
                <div className="text-sm mb-2 linotte-font">
                    © {new Date().getFullYear()} Njoy. Tous droits réservés.
                </div>
                <div className="flex space-x-4">
                    <a 
                        href="https://www.instagram.com/njoy.be?igsh=MTVzMGYzamZ5ZGwwag==" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label="Instagram" 
                        className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                    >
                        <FaInstagram className="w-6 h-6" /> {/* Icône Instagram */}
                    </a>
                    <a 
                        href="https://x.com/njoy_be?s=21&t=kTwzaXyJk3LtT29kG-LPEw" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label="Twitter" 
                        className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                    >
                        <FaTwitter className="w-6 h-6" /> {/* Icône Twitter */}
                    </a>
                    <a 
                        href="https://www.tiktok.com/@njoy.be?_t=8qgI4QNW5vF&_r=1" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label="TikTok" 
                        className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                    >
                        <FaTiktok className="w-6 h-6" /> {/* Icône TikTok */}
                    </a>
                    <a 
                        href="https://www.facebook.com/share/oD8hhEzmDkWVjx2r/?mibextid=LQQJ4d" // Remplacez par votre lien Facebook
                        target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label="Facebook" 
                        className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                    >
                        <FaFacebook className="w-6 h-6" /> {/* Icône Facebook */}
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
