import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../stylessheets/productDetail.css'; 

// Exemple de données produit
const products = [
  { id: 1, name: "T-Shirt Noir N'JOY", price: 35.99, description: "T-Shirt Noir N'JOY (T-Shirt Oversize)", sizes: ['S', 'M', 'L', 'XL'], image: '/assert/1b37d3dd-205b-44f7-aed9-ddc386400cf2.JPG' },
  { id: 2, name: "Cup N'JOY", price: 12.99, description: "Cup N'JOY", image: '/assert/51a33a16-e280-4a93-8ec3-5b245c19ff8d.JPG' },
  { id: 3, name: "Pack T-shirt N'JOY + Cup N'JOY", price: 45, description: 'Un pack complet avec un T-shirt et une cup', sizes: ['S', 'M', 'L', 'XL'], image: '/assert/IMG_8711.jpg' },
];

const ProductDetail = () => {
  const { id } = useParams();
  const product = products.find(p => p.id === parseInt(id));

  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState(product.sizes ? product.sizes[0] : null);  // Initialiser la taille si le produit a des tailles
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [zoomed, setZoomed] = useState(false); // État pour le zoom

  const handleAddToCart = () => {
    if (!/^\d+$/.test(quantity) || quantity <= 0) {
      setErrorMessage('Veuillez entrer un nombre valide.');
      return;
    }

    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    const newItem = {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: parseInt(quantity),
      size: product.sizes ? size : null,  // Inclure la taille si applicable
      image: product.image, // Ajout de l'image ici
    };

    const existingItemIndex = cart.findIndex(
      (item) => item.id === newItem.id && item.size === newItem.size
    );

    if (existingItemIndex >= 0) {
      cart[existingItemIndex].quantity += newItem.quantity;
    } else {
      cart.push(newItem);
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    setShowAlert(true);
    setErrorMessage('');
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setQuantity(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Veuillez entrer un nombre valide.');
    }
  };

  return (
    <div className="relative bg-gray-100 min-h-screen">
      {/* Conteneur Produit centré avec espacement du haut */}
      <div className="p-1 bg-white mt- rounded-lg shadow-md mx-50 my-1 max-w-4xl mx-auto"> {/* Ajout de coins arrondis et d'une ombre */}
        {showAlert && (
          <div id="alert-3" className="flex items-center p-2 mb-4 text-green-800 rounded-lg bg-green-50" role="alert">
            <svg className="flex-shrink-0 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div className="ms-3 text-sm linotte-font">
              {product.name} a été ajouté au panier ! 
              <Link to="/panier" className="font-semibold underline hover:no-underline text-green-600"> Voir le panier</Link>.
            </div>
            <div className="ms-3 text-sm linotte-font">
              <p>Ou alors </p>
            </div>
            <div className="ms-3 text-sm linotte-font">
              <Link to="/" className="font-semibold underline hover:no-underline text-green-600"> Continuer vos achats</Link>.
            </div>
            <button 
              type="button" 
              className="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8" 
              onClick={() => setShowAlert(false)} 
              aria-label="Close">
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
            </button>
          </div>
        )}
        {errorMessage && (
          <div className="mb-4 text-red-600 font-semibold">{errorMessage}</div>
        )}
        <h1 className="text-3xl linotte-font mb-2 text-gray-800">{product.name}</h1>

        {/* Image avec zoom */}
        <div className="relative mb-6">
          <img 
            src={product.image} 
            alt={product.name} 
            className="w-full max-h-96 object-contain cursor-pointer rounded-lg shadow-md transition-transform duration-300 hover:scale-105" 
            onClick={() => setZoomed(true)} // Ouvrir le zoom au clic
          />
          {/* Overlay pour zoom */}
          {zoomed && (
            <div 
              className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50" 
              onClick={() => setZoomed(false)} // Fermer le zoom au clic
            >
              <img 
                src={product.image} 
                alt={product.name} 
                className="max-w-full max-h-full object-contain border border-white rounded-lg shadow-lg" 
              />
            </div>
          )}
        </div>

        <p className="text-gray-700 mb-4 linotte-font">{product.description}</p>
        <p className="linotte-font text-lg text-gray-800">{product.price}€</p>

        {/* Sélection de la taille si applicable */}
        {product.sizes && (
          <div className="mb-4">
            <label className="block text-sm linotte-font text-gray-700">Choisir la taille {product.id === 3 && "(pour le T-shirt)"} :</label>
            <select 
              value={size} 
              onChange={(e) => setSize(e.target.value)} 
              className="mt-2 block max-w-xs border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            >
              {product.sizes.map((s) => (
                <option key={s} value={s}>{s}</option>
              ))}
            </select>
          </div>
        )}

        <div className="flex items-center mb-6">
          <label className="mr-2 text-sm linotte-font text-gray-700">Quantité :</label>
          <input
            type="number"
            min="1"
            value={quantity}
            onChange={handleQuantityChange}
            className="border border-gray-300 rounded-md p-1 text-center w-20 focus:ring-green-500 focus:border-green-500"
          />
        </div>

        <button
          onClick={handleAddToCart}
          type="button"
          className="text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 linotte-font rounded-full text-sm px-6 py-3 transition duration-200"
        >
          Ajouter au panier
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
